import Link from "next/link";

const DontWaitBanner = ({ link }) => {
  return (
    <div className="dont-wait-container">
      <div className="dont-wait-banner">
        <div className="copy">
          <h2>Don't wait, Protect Your Brand now</h2>
          <Link href={link ? link : "#"}>
            <a className="orange-btn">
              Start Filing
            </a>
          </Link>
        </div>
      </div>
      <div className="dont-wait-bottom-text">All information submitted to Trademark Genius shall be regarded as strictly confidential. Trademark Genius will not reveal any intellectual property information to any organization other than as necessary for submission to the USPTO. </div>
    </div>
  );
};

export default DontWaitBanner;
