import getConfig from "next/config";
import FAQ from "../components/FAQ";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DontWaitBanner from "../components/DontWaitBanner";
import SearchComponent from "../components/search/SearchComponent";
import ReviewBox from "../components/ReviewBox";
import { useEffect } from "react";

const { publicRuntimeConfig } = getConfig();

export default function Home() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  useEffect(() => {
    localStorage.removeItem("path");
    localStorage.removeItem("product");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenDiscount");
    localStorage.removeItem("updated");
  }, []);
  return (
    <div className="home">
      <SearchComponent />

      <div className="recently-trademarks">
        <h2>Recently Trademarked</h2>
        <Carousel responsive={responsive} showDots={true} infinite={true} renderDotsOutside={true} ssr={true}>
          <div className="trademark t1">
            <img src={`${publicRuntimeConfig.basePath}/bumble.png`} alt="" layout="fill" />
          </div>

          <div className="trademark t2">
            <img src={`${publicRuntimeConfig.basePath}/databrix.png`} alt="" layout="fill" />
          </div>

          <div className="trademark t3">
            <img src={`${publicRuntimeConfig.basePath}/fabletics.png`} alt="" layout="fill" />
          </div>
          <div className="trademark t4">
            <img src={`${publicRuntimeConfig.basePath}/mobileeye.png`} alt="" layout="fill" />
          </div>

          <div className="trademark t5">
            <img src={`${publicRuntimeConfig.basePath}/qlik.png`} alt="" layout="fill" />
          </div>

          <div className="trademark t6">
            <img src={`${publicRuntimeConfig.basePath}/roblox.png`} alt="" layout="fill" />
          </div>
          <div className="trademark t7">
            <img src={`${publicRuntimeConfig.basePath}/rivian.png`} alt="" layout="fill" />
          </div>
        </Carousel>
      </div>

      <div className="step-process">
        <h2>3 Step Process</h2>
        <h3>Get your registered trademark in 3 easy steps.</h3>
        <div className="steps-wrapper">
          <div className="step-box">
            <h4>Search for Availability</h4>
            <img src={`${publicRuntimeConfig.basePath}/step-process-1.png`} alt="" layout="fill" />
            <p>Check to see if your trademark is already being used.</p>
          </div>
          <div className="next" />
          <div className="step-box">
            <h4>Answer a few Questions</h4>

            <img src={`${publicRuntimeConfig.basePath}/step-process-2.png`} alt="" layout="fill" />
            <p>Fill out our simple questionnaire to begin the registration process.</p>
          </div>
          <div className="next" />
          <div className="step-box">
            <h4>Application Filling</h4>

            <img src={`${publicRuntimeConfig.basePath}/step-process-3.png`} alt="" layout="fill" />
            <p>Your application will be filed with the U.S. Patent and Trademark Office (USPTO)</p>
          </div>
        </div>
      </div>

      <div className="testimonials">
        <div className="content-wrapper">
          <div className="copy">
            <h2>
              Customer Reviews
              <br />& Testimonials
            </h2>
            <h3>See why others are choosing Trademark Genius!</h3>
          </div>
          <div className="t-boxs-wrapper">
            <div className="t-col">
              <ReviewBox
                rate={5}
                name="Mark D"
                company="Trademark Genius"
                text="Trademark Genius helped register my companies trademark.
The process was simple, their customer service was excellent, and my trademark was registered without any issues.
Highly recommend Trademark Genius."
              />
              <ReviewBox
                rate={5}
                name="Kelly T"
                company="Trademark Genius"
                text="I contacted Trademark Genius after running into many issues trying to register my companies trademark myself.
They helped solve every issue i ran into. Would definitely recommend spending the money to have professionals handle
this process."
              />
            </div>
            <div className="t-col">
              <ReviewBox
                rate={5}
                name="Terry F"
                company="Trademark Genius"
                text="Got my trademark registered with USPTO without any issues.
Their dashboard is a great tool and helped me track the progress of my application all the way to completion.
Will use them again for any other TM needs."
              />
              <ReviewBox
                rate={5}
                name="Devin M"
                company="Trademark Genius"
                text="My experience with TMG was great.
Their customer service team was friendly and helpful.
The process was quick & simple.
Thanks!"
              />

            </div>
            <div className="t-col">
              <ReviewBox
                rate={5}
                name="Lisa N"
                company="Trademark Genius"
                text="My trademark registration was a unique situation that required some extra work, the team helped guide me through this daunting process and got my application submit to USPTO (and was approved without issues)
Thanks TMG!"
              />
              <ReviewBox
                rate={5}
                name="Marshall N"
                company="Trademark Genius"
                text="Great experience with Trademark Genius. They were knowledgeable, quick and accurate.
Helped iron out a few issues the USPTO was giving my application.
I’d definitely use them again."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="protect-banner">
        <div className="copy">
          <h2>Ensure that competitors don’t <br/>infringe on your most vital assets.</h2>
          <p>100% Satisfaction Guaranteed.</p>
        </div>
      </div>

      <div className="step-process protect">
        <h2>Protect Your Business with our Services</h2>
        <div className="steps-wrapper">
          <div className="step-box">
            <h4>Trademark Registration</h4>
            <img src={`${publicRuntimeConfig.basePath}/protect-1.png`} alt="" layout="fill" />
          </div>
          <div className="step-box">
            <h4>Trademark Alert Monitoring</h4>
            <img src={`${publicRuntimeConfig.basePath}/protect-2.png`} alt="" layout="fill" />
          </div>
          <div className="step-box">
            <h4>Trademark Renewals</h4>
            <img src={`${publicRuntimeConfig.basePath}/protect-3.png`} alt="" layout="fill" />
          </div>
        </div>
      </div>

      <FAQ leftSide={true} />
      <DontWaitBanner />
    </div>
  );
}
